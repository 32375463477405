@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:700|Quicksand:700&display=swap);
*, *::before, *::after {
  box-sizing: border-box; }

body {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 16px;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold; }

.slideInUp {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: slideInUp;
          animation-name: slideInUp; }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

.Screen_wrapper__1HH4x, .Screen_wrapperSecondary__E-jbG {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  color: white; }

.Screen_wrapperSecondary__E-jbG {
  height: 50vh;
  background-position: center;
  background-size: cover; }
  .Screen_wrapperSecondary__E-jbG .Screen_content__3bCck {
    background-color: transparent; }

.Screen_image___ZLlE {
  position: absolute;
  bottom: -10vh;
  width: 100%;
  height: 70vh;
  z-index: -1; }
  .Screen_image___ZLlE::before, .Screen_image___ZLlE::after {
    content: '';
    display: block;
    width: 100%;
    height: 50px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(black), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(black, rgba(0, 0, 0, 0));
    background-image: linear-gradient(black, rgba(0, 0, 0, 0));
    position: absolute;
    z-index: 1; }
  .Screen_image___ZLlE::before {
    top: calc(10vh - 1px);
    left: 0; }
  .Screen_image___ZLlE::after {
    bottom: calc(10vh - 1px);
    left: 0;
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1); }

.Screen_content__3bCck {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vh;
  background-color: black;
  justify-content: center;
  align-items: center; }

.ParallaxBackground_parallax__uTyI8, .ParallaxBackground_parallaxSecondary__3G4h8 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  .ParallaxBackground_parallaxSecondary__3G4h8 {
    height: 100vh; }

.Logo_wrapper__21E-1 {
  position: fixed;
  top: 15px;
  left: 15px;
  width: 277px;
  height: 100px;
  color: blue;
  z-index: 10;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transition: -webkit-transform 200ms 100ms ease-in-out;
  transition: -webkit-transform 200ms 100ms ease-in-out;
  transition: transform 200ms 100ms ease-in-out;
  transition: transform 200ms 100ms ease-in-out, -webkit-transform 200ms 100ms ease-in-out; }
  @media (min-width: 768px) {
    .Logo_wrapper__21E-1 {
      -webkit-transform: scale(0.7);
              transform: scale(0.7); } }

.Logo_scrolled__3QfJM {
  -webkit-transform: translate3d(-10px, -10px, 0) scale(0.3);
          transform: translate3d(-10px, -10px, 0) scale(0.3); }
  @media (min-width: 768px) {
    .Logo_scrolled__3QfJM {
      -webkit-transform: translate3d(-5px, -5px, 0) scale(0.5);
              transform: translate3d(-5px, -5px, 0) scale(0.5); } }

.MenuShadow_wrapper__PLwGP {
  position: fixed;
  height: 50px;
  width: 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(black), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(black, rgba(0, 0, 0, 0));
  background-image: linear-gradient(black, rgba(0, 0, 0, 0));
  z-index: 9;
  -webkit-transition: opacity 200ms 100ms ease-in-out;
  transition: opacity 200ms 100ms ease-in-out; }

.GreeterScreen_wrapper__2qjTS {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  color: white; }
  .GreeterScreen_wrapper__2qjTS::after {
    content: '';
    display: block;
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(black));
    background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0), black);
    background-image: linear-gradient(rgba(0, 0, 0, 0), black); }

.Greeter_wrapper__1ur9E {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  top: calc(50vh - calc(200px / 2));
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7); }
  @media (min-width: 576px) {
    .Greeter_wrapper__1ur9E {
      height: 300px;
      top: calc(50vh - calc(300px / 2)); } }
  @media (min-width: 992px) {
    .Greeter_wrapper__1ur9E {
      height: 370px;
      top: calc(50vh - calc(370px / 2)); } }

.Title_title__1jPs1 {
  color: #ecc78c;
  text-transform: uppercase;
  line-height: 29px;
  font-size: 28px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
  margin: 0; }
  @media (min-width: 576px) {
    .Title_title__1jPs1 {
      font-size: 35px;
      line-height: 36px; } }
  @media (min-width: 992px) {
    .Title_title__1jPs1 {
      font-size: 50px;
      line-height: 51px; } }
  .Title_titleStandartFont__3Dr38 {
    text-transform: none; }

.Subtitle_wrapper__3kqcN, .Subtitle_wrapperGreeter__2irYc {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  max-width: 95%; }
  .Subtitle_wrapper__3kqcN span, .Subtitle_wrapperGreeter__2irYc span {
    display: block; }
  @media (min-width: 576px) {
    .Subtitle_wrapper__3kqcN, .Subtitle_wrapperGreeter__2irYc {
      font-size: 1.5em; } }
  @media (min-width: 992px) {
    .Subtitle_wrapper__3kqcN, .Subtitle_wrapperGreeter__2irYc {
      font-size: 2em; } }

@media (min-width: 1200px) {
  .Subtitle_wrapperGreeter__2irYc {
    flex-direction: row;
    font-size: 1.8em; } }

.ClickMe_wrapper__54wJP {
  position: absolute;
  width: 180px;
  height: 102px;
  left: calc(50% - calc(180px / 2));
  top: calc(100vh - 102px - 75px);
  cursor: pointer; }

.ClickMe_button__36ptd {
  position: relative;
  width: 100%;
  height: 34.8px;
  font-size: 17px;
  line-height: 17px;
  padding: 4px 0 0;
  border: 4px solid white; }

.ClickMe_arrow__1sVY- {
  position: relative;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px); }

.SponsorsScreen_wrapper__YswYQ {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: white;
  padding: 55px 15px;
  overflow: hidden; }
  .SponsorsScreen_wrapper__YswYQ h1 {
    font-size: 1.4em;
    color: black; }
    @media (min-width: 768px) {
      .SponsorsScreen_wrapper__YswYQ h1 {
        font-size: 2em; } }
  @media (min-width: 768px) {
    .SponsorsScreen_wrapper__YswYQ {
      min-height: 0; } }

.SponsorsScreen_sponsors__71xab {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }

.SponsorsScreen_logo__3xx9x {
  width: 50%;
  padding: 15px; }
  @media (min-width: 576px) {
    .SponsorsScreen_logo__3xx9x {
      padding: 25px; } }
  @media (min-width: 768px) {
    .SponsorsScreen_logo__3xx9x {
      padding: 35px;
      width: 33.33%; } }
  @media (min-width: 992px) {
    .SponsorsScreen_logo__3xx9x {
      padding: 20px;
      margin-top: 25px; } }
  @media (min-width: 1200px) {
    .SponsorsScreen_logo__3xx9x {
      width: 14%;
      margin-top: 60px; } }
  @media (min-width: 1600px) {
    .SponsorsScreen_logo__3xx9x {
      margin-top: 50px; } }

.Menu_wrapper__3jCi5 {
  display: none;
  position: fixed;
  top: 15px;
  right: 15px;
  height: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 10;
  -webkit-transform-origin: top right;
          transform-origin: top right;
  -webkit-transition: -webkit-transform 200ms 100ms ease-in-out;
  transition: -webkit-transform 200ms 100ms ease-in-out;
  transition: transform 200ms 100ms ease-in-out;
  transition: transform 200ms 100ms ease-in-out, -webkit-transform 200ms 100ms ease-in-out; }
  @media (min-width: 768px) {
    .Menu_wrapper__3jCi5 {
      display: flex;
      width: 450px;
      font-size: 1em; } }
  @media (min-width: 992px) {
    .Menu_wrapper__3jCi5 {
      font-size: 1.4em;
      width: 600px; } }
  .Menu_wrapper__3jCi5 li {
    cursor: pointer; }

.Menu_scrolled__3b5Vr {
  -webkit-transform: translate3d(0, -15px, 0) scale(0.95);
          transform: translate3d(0, -15px, 0) scale(0.95); }

