.wrapper {
  $width: 180px;
  $height: 102px;

  position: absolute;
  width: $width; height: $height;
  left: calc(50% - calc(#{$width} / 2));
  top: calc(100vh - #{$height} - 75px);

  cursor: pointer;
}

.button {
  position: relative;
  width: 100%;
  height: 34.8px;
  font-size: 17px;
  line-height: 17px;
  padding: 4px 0 0;
  border: 4px solid white;
}

.arrow {
  position: relative;
  transform: translateY(-1px)
}