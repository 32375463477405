.wrapper {
  $width: 277px;
  $height: 100px;

  position: fixed;
  top: 15px; left: 15px;
  width: $width; height: $height;
  color: blue;
  z-index: 10;
  transform-origin: left top;
  transform: scale(.5);
  transition: transform 200ms 100ms ease-in-out;

  @media (min-width: 768px) { transform: scale(.7); }
}

.scrolled {
  transform: translate3d(-10px, -10px, 0) scale(.3);
  @media (min-width: 768px) {
    transform: translate3d(-5px, -5px, 0) scale(.5);
  }
}