.wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  color: white;

  &::after {
    content: '';
    display: block;
    width: 100%; height: 50px;
    position: absolute;
    bottom: 0; left: 0;
    background-image: linear-gradient(rgba(0,0,0, 0), rgba(0,0,0, 1));
  }
}
