.wrapper {
  $height: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: $height;
  top: calc(50vh - calc(#{$height} / 2));
  position: absolute;

  background-color: rgba(0,0,0, 0.7);

  @media (min-width: 576px) {
    $height: 300px;
    height: $height;
    top: calc(50vh - calc(#{$height} / 2));
  }

  @media (min-width: 992px) {
    $height: 370px;
    height: $height;
    top: calc(50vh - calc(#{$height} / 2));
  }
}
