.title {
  color: #ecc78c;
  text-transform: uppercase;
  line-height: 29px;
  font-size: 28px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
  margin: 0;

  @media (min-width: 576px) {
    font-size: 35px;
    line-height: 36px;
  }

  @media (min-width: 992px) {
    font-size: 50px;
    line-height: 51px;
  }

  &StandartFont {
    text-transform: none;
  }
}
