.wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  color: white;
}

.wrapperSecondary {
  @extend .wrapper;
  height: 50vh;

  background-position: center;
  background-size: cover;

  .content { background-color: transparent; }
}

.image {
  position: absolute;
  bottom: -10vh;
  width: 100%; height: 70vh;
  z-index: -1;

  &::before, &::after {
    content: '';
    display: block;
    width: 100%;
    height: 50px;
    background-image: linear-gradient(rgba(0,0,0, 1), rgba(0,0,0, 0));
    position: absolute;
    z-index: 1;
  }

  &::before {
    top: calc(10vh - 1px); left: 0;
  }

  &::after {
    bottom: calc(10vh - 1px); left: 0;
    transform: scaleY(-1);
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vh;
  background-color: black;
  justify-content: center;
  align-items: center;
}
