@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:700|Quicksand:700&display=swap');

*, *::before, *::after { box-sizing: border-box; }
body {
  margin: 0; padding: 0;
  text-align: center;

  font-size: 16px;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
}

.slideInUp {
  animation-duration: 1s; animation-fill-mode: both;
  animation-name: slideInUp;
}

@keyframes slideInUp {
  from { transform: translate3d(0, 100%, 0); visibility: visible; }
  to { transform: translate3d(0 ,0, 0); opacity: 1 }
}