.wrapper {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  max-width: 95%;

  span {
    display: block;
  }

  @media (min-width: 576px) { font-size: 1.5em; }
  @media (min-width: 992px) { font-size: 2em; }
}

.wrapperGreeter {
  @extend .wrapper;
  @media (min-width: 1200px) { flex-direction: row; font-size: 1.8em }
}