.wrapper {
  display: none;
  @media (min-width: 768px) {
    display: flex;
    width: 450px;
    font-size: 1em;
  }

  @media (min-width: 992px) { font-size: 1.4em; width: 600px; }

  position: fixed;
  top: 15px; right: 15px;
  height: 50px;

  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  color: white;
  list-style: none;
  padding: 0; margin: 0;
  z-index: 10;

  transform-origin: top right;
  transition: transform 200ms 100ms ease-in-out;

  li { cursor: pointer }
}

.scrolled {
  transform: translate3d(0, -15px, 0) scale(0.95);
}