.wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: white;
  padding: 55px 15px;
  overflow: hidden;

  h1 {
    font-size: 1.4em;
    color: black;

    @media (min-width: 768px) { font-size: 2em; }
  }

  @media (min-width: 768px) { min-height: 0; }
}

.sponsors {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.logo {
  width: 50%;
  padding: 15px;

  @media (min-width: 576px) { padding: 25px; }
  @media (min-width: 768px) { padding: 35px; width: 33.33%}
  @media (min-width: 992px) {
    padding: 20px;
    margin-top: 25px;
  }
  @media (min-width: 1200px) { width: 14%; margin-top: 60px;}
  @media (min-width: 1600px) { margin-top: 50px; }
}